import React from "react";
import { Box } from "ticeboxes";
import { TimeEntryInput } from "@TiceTrackerApp/time-tracker-hooks/dist/graphql";
import { emptyTimeEntryInput } from "@TiceTrackerApp/time-tracker-hooks/dist/hooks/useTimeEntry";
import { InputField } from "time-tracker-lib";
import ProjectInputField from "./ProjectInputField";
import Counter from "./Counter";
import { Entry } from "@TiceTrackerApp/time-tracker-hooks/dist/types";

interface Props {
  timeEntry: Entry | TimeEntryInput;
}

const TimeTrackerBar: React.FC<Props> = ({ timeEntry }) => (
  <Box d-block d-md-flex align-items="baseline">
    <Box mb="4" mr-md="5">
      <Counter
        initialStartTime={timeEntry.startTime}
        running={timeEntry !== emptyTimeEntryInput}
      />
    </Box>
    <Box mr-md="3">
      <ProjectInputField />
    </Box>
    <Box flex-grow="1">
      <InputField name="description" placeholder="Description..." />
    </Box>
  </Box>
);

export default TimeTrackerBar;
