import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Flex } from "ticeboxes";
import {
  Button,
  H1,
  P,
  Center,
  BottomRight,
  LayoutContainer,
} from "time-tracker-lib";
import { useResendActivationEmailMutation } from "@TiceTrackerApp/time-tracker-hooks/dist/graphql";
import redirectToEmail from "../utils/redirectToEmail";

interface Props {}

const VerifyEmail: React.FC<Props> = () => {
  const history = useHistory();
  const [resendActivationEmail, { data }] = useResendActivationEmailMutation();

  const hasSent = data?.resendActivationEmail.success;

  return (
    <LayoutContainer>
      <Center>
        <Box>
          <H1>Verify your email.</H1>
          <Box my="4">
            <P cursive>
              To continue using Time Tracker App, please verify your email.
              We've sent you a confirmation email when you signed up.
            </P>
          </Box>
          <Flex mt="3">
            <Button
              secondary
              onClick={redirectToEmail("gmail")}
              className="mr-3"
            >
              Gmail
            </Button>
            <Button onClick={redirectToEmail("outlook")} secondary>
              Outlook
            </Button>
          </Flex>
          {hasSent ? (
            <Box mt="5">
              <P cursive>Sent!</P>
            </Box>
          ) : (
            <>
              <Box mt="5" mb="2">
                <P cursive>Didn't receive it?</P>
              </Box>

              <Box>
                <Button secondary small onClick={() => resendActivationEmail()}>
                  Resend activation email
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Center>
      <BottomRight>
        <Box m="3">
          <Button secondary onClick={() => history.push("/dashboard")}>
            Back
          </Button>
        </Box>
      </BottomRight>
    </LayoutContainer>
  );
};

export default VerifyEmail;
