import React from "react";
import { Formik, Form } from "formik";
import {
  Button,
  H1,
  InputField,
  BottomRight,
  Center,
  LayoutContainer,
} from "time-tracker-lib";
import { toErrorMap } from "@TiceTrackerApp/time-tracker-hooks/dist/utils/toErrorMap";
import { useHistory } from "react-router-dom";
import { useRegisterMutation } from "@TiceTrackerApp/time-tracker-hooks/dist/graphql";
import updateCacheOnRegister from "@TiceTrackerApp/time-tracker-hooks/dist/utils/cache/updateCacheOnRegister";
import UnauthRequired from "../components/auth/UnauthRequired";
import useQueryParams from "../hooks/useQueryParams";

const Register: React.FC<{}> = () => {
  const history = useHistory();
  const queryParams = useQueryParams();

  const redirectUrlOnRegister =
    queryParams.get("promo") === "launch"
      ? "/become-member?promo=launch"
      : "/dashboard";

  const [register] = useRegisterMutation();

  const handleSubmit = async (values: any, { setErrors }: any) => {
    const { data } = await register({
      variables: values,
      update: updateCacheOnRegister,
    });

    if (data?.register.errors?.length) {
      setErrors(toErrorMap(data.register.errors));
    } else {
      history.push(redirectUrlOnRegister);
    }
  };

  return (
    <>
      <UnauthRequired />

      <LayoutContainer>
        <Center>
          <div style={{ width: "100%" }}>
            <div className="my-4 my-md-5">
              <H1>Register.</H1>
            </div>
            <Formik
              initialValues={{
                username: "",
                email: "",
                password: "",
                passwordConfirmation: "",
              }}
              onSubmit={handleSubmit}
            >
              {() => (
                <Form>
                  <div>
                    <InputField name="username" placeholder="Name" />
                    <InputField name="email" placeholder="Email" type="email" />
                  </div>
                  <div className="mt-5">
                    <InputField
                      name="password"
                      placeholder="Password"
                      type="password"
                    />
                    <InputField
                      name="passwordConfirmation"
                      placeholder="Retype password"
                      type="password"
                    />
                  </div>
                  <Button type="submit">Register</Button>
                </Form>
              )}
            </Formik>
          </div>
        </Center>
      </LayoutContainer>

      <BottomRight className="m-3">
        <Button secondary onClick={() => history.push("/login")}>
          Log in
        </Button>
      </BottomRight>
    </>
  );
};

export default Register;
