import React, { useEffect } from "react";
import { useMeQuery } from "@TiceTrackerApp/time-tracker-hooks/dist/graphql";
import { useHistory } from "react-router-dom";

interface Props {}

const UnauthRequired: React.FC<Props> = ({ children }) => {
  const history = useHistory();

  const { loading, data } = useMeQuery();

  useEffect(() => {
    if (!loading && data?.me) {
      history.push("/dashboard");
    }
  }, [loading, data, history]);

  return !loading ? <>{children}</> : null;
};

export default UnauthRequired;
