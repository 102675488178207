import { useApolloClient } from "@apollo/client";
import React from "react";
import { useHistory } from "react-router-dom";
import { useLogoutMutation } from "@TiceTrackerApp/time-tracker-hooks/dist/graphql";
import { Button } from "time-tracker-lib";

const LogoutButton: React.FC<{}> = () => {
  const history = useHistory();
  const [logout] = useLogoutMutation();
  const apolloClient = useApolloClient();

  const handleLogout = async () => {
    const { data } = await logout();

    if (data?.logout.errors?.length) {
      console.log(data?.logout.errors);
      return;
    }

    apolloClient.cache.reset();

    history.push("/");
  };

  return (
    <Button secondary onClick={handleLogout}>
      Log out
    </Button>
  );
};

export default LogoutButton;
