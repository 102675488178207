import { Step } from "../Tour";

const setupTour: Step[] = [
  {
    selector: "#projectName",
    text: "Fill in a project name",
    nextStepRequirement: {
      elementSelector: "#projectName",
      event: "keyup",
      automatic: false,
    },
  },
  {
    selector: "#description",
    text: "Fill in a description",
    nextStepRequirement: {
      elementSelector: "#description",
      event: "keyup",
      automatic: false,
    },
  },
  {
    selector: "#stopwatchButton",
    text: "Click the stopwatch to start the timer!",
    nextStepRequirement: {
      elementSelector: "#stopwatchButton",
      event: "click",
      automatic: true,
    },
  },
  {
    selector: "#stopwatchButton",
    text: "Click again to stop.",
    nextStepRequirement: {
      elementSelector: "#stopwatchButton",
      event: "click",
      automatic: true,
    },
  },
  {
    selector: ".folder-icon-link",
    text: "Click on the folder to go to the project overview",
    cardPlacement: "left",
    nextStepRequirement: {
      elementSelector: ".folder-icon-link",
      event: "click",
      automatic: true,
    },
  },
  {
    selector: "#projectBackBtn",
    text: "Click on the arrow to go back",
    nextStepRequirement: {
      elementSelector: "#projectBackBtn",
      event: "click",
      automatic: true,
    },
  },
  {
    text: "All set! Happy time tracking!",
    buttonPlacement: "center",
    buttonText: "Finish",
  },
];

export default setupTour;
