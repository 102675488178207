import { Step } from "../../components/wizard/Tour";

const scrollElementIntoViewForStep = (step: Step) => {
  if (!step.selector) return;

  const elem = document.querySelector(step.selector);
  if (!elem) return;

  elem.scrollIntoView({ behavior: "smooth" });
};

export default scrollElementIntoViewForStep;
