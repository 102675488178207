import dayjs from "dayjs";
import React from "react";
import { HoverButton } from "time-tracker-lib/dist/components/HoverButton";
import { P } from "time-tracker-lib/dist/components/Typography";
import TransformToInputWrapper from "time-tracker-lib/dist/components/form/TransformToInputWrapper";
import { UpdateTimeEntryFieldProps } from "./UpdateDuration";
import convertTime from "@TiceTrackerApp/time-tracker-hooks/dist/utils/table/convertTime";

type Props = UpdateTimeEntryFieldProps & {
  type: "startTime" | "endTime";
};

const UpdateTime: React.FC<Props> = ({ type, onSubmit, timeEntry }) => {
  const time = dayjs(timeEntry[type]).format("HH:mm");

  const calculateNewTime = (value: string) => {
    const newTime = convertTime(value, timeEntry[type]);
    onSubmit({ ...timeEntry, [type]: newTime });
  };
  return (
    <TransformToInputWrapper initialValue={time} onSubmit={calculateNewTime}>
      <HoverButton className={type} style={{ cursor: "text" }}>
        <P className={type}>{time}</P>
      </HoverButton>
    </TransformToInputWrapper>
  );
};

export default UpdateTime;
