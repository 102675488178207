import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import styled from "styled-components";

const DropDownWrapper = styled.div`
  position: relative;
`;

interface DropDownMenuProps {
  leftOffset: number;
  isOpen: boolean;
}

const DropDownMenu = styled.div<DropDownMenuProps>`
  position: absolute;
  z-index: 1;
  left: ${({ leftOffset, isOpen }) =>
    isOpen ? leftOffset : leftOffset + 30}px;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: left 200ms ease-out, opacity 100ms ease-out;
`;

const StyledIconButton = styled(IconButton)`
  &:focus {
    outline: none;
  }
`;

interface Props {
  children: React.ReactNode | React.ReactNodeArray;
  leftOffsetAppear: number;
}

const MoreDropDown: React.FC<Props> = ({ children, leftOffsetAppear }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <DropDownWrapper>
      <StyledIconButton onClick={() => setOpen(!isOpen)}>
        <MoreHorizIcon />
      </StyledIconButton>

      <DropDownMenu isOpen={isOpen} leftOffset={leftOffsetAppear}>
        {children}
      </DropDownMenu>
    </DropDownWrapper>
  );
};

export default MoreDropDown;
