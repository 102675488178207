import dayjs from "dayjs";
import React from "react";

interface Props {
  duration: number;
}

const DurationInWords: React.FC<Props> = ({ duration }) => {
  const dur = dayjs.duration(duration);

  const hours = Math.floor(dur.asHours());
  const minutes = dur.minutes();
  const seconds = dur.seconds();

  return (
    <>
      {hours} hours, {minutes} minutes and {seconds} seconds
    </>
  );
};

export default DurationInWords;
