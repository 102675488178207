import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import React, { useEffect } from "react";
import { Box, Flex } from "ticeboxes";
import { H1, Span, StopwatchButton } from "time-tracker-lib";
import { useCounter } from "@TiceTrackerApp/time-tracker-hooks";

dayjs.extend(duration);

interface Props {
  initialStartTime?: Date | string;
  running?: boolean;
}

const Counter: React.FC<Props> = ({
  initialStartTime = new Date(),
  running,
}) => {
  const { ms, m, s, h, setRunning } = useCounter(initialStartTime);

  useEffect(() => {
    if (running) {
      setRunning(true);
    } else {
      setRunning(false);
    }
  }, [running, setRunning]);

  return (
    <Flex align-items="end">
      <Box mr="4" pr="2">
        <StopwatchButton playing={running} />
      </Box>
      <Flex align-items="baseline" style={{ position: "relative", bottom: -2 }}>
        <H1 display>
          {h}:{m}:{s}
        </H1>
        <Box ml="2">
          <Span style={{ fontSize: "1.1em" }}>{ms}</Span>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Counter;
