import React from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import Table from "../table/Table";
import { useTimeEntry } from "@TiceTrackerApp/time-tracker-hooks";
import { TimeEntriesQuery } from "@TiceTrackerApp/time-tracker-hooks/dist/graphql";

dayjs.extend(duration);

type Props = TimeEntriesQuery & {
  onStartTimeEntry: (entry: any) => void;
};

const TimeEntriesList: React.FC<Props> = ({
  timeEntries,
  onStartTimeEntry,
}) => {
  const { onUpdateTimeEntry, onRemoveTimeEntry } = useTimeEntry({});

  return (
    <Table
      timeEntries={timeEntries}
      onStart={onStartTimeEntry}
      onEdit={onUpdateTimeEntry}
      onRemove={onRemoveTimeEntry}
    />
  );
};

export default TimeEntriesList;
