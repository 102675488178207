import React from "react";
import { Box } from "ticeboxes";
import {
  Button,
  H1,
  BottomRight,
  Center,
  LayoutContainer,
} from "time-tracker-lib";
import { useHistory } from "react-router-dom";
import StatTable from "../../components/superadmin/StatTable";
import { useStatsQuery } from "@TiceTrackerApp/time-tracker-hooks/dist/graphql";
import SuperAdminRequired from "../../components/auth/SuperAdminRequired";
import { Loading } from "time-tracker-lib";

interface Props {}

const Stats: React.FC<Props> = () => {
  const history = useHistory();
  const { loading, data } = useStatsQuery({ pollInterval: 1000 });

  return (
    <SuperAdminRequired>
      <LayoutContainer>
        <Center>
          <Box style={{ maxWidth: 500, width: "100%" }}>
            <H1>Stats.</H1>

            <Box mt="5">
              {loading && <Loading />}
              {data && <StatTable stats={data.stats} />}
            </Box>
          </Box>
        </Center>
      </LayoutContainer>
      <BottomRight className="m-3">
        <Button secondary onClick={() => history.push("/dashboard")}>
          Back
        </Button>
      </BottomRight>
    </SuperAdminRequired>
  );
};

export default Stats;
