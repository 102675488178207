import dayjs from "dayjs";
import React from "react";
import { HoverButton } from "time-tracker-lib/dist/components/HoverButton";
import { P } from "time-tracker-lib/dist/components/Typography";
import TransformToInputWrapper from "time-tracker-lib/dist/components/form/TransformToInputWrapper";
import { UpdateTimeEntryFieldProps } from "./UpdateDuration";
import relativeTime from "dayjs/plugin/relativeTime";
import convertDate from "@TiceTrackerApp/time-tracker-hooks/dist/utils/table/convertDate";

dayjs.extend(relativeTime);

const UpdateDate: React.FC<UpdateTimeEntryFieldProps> = ({
  timeEntry,
  onSubmit,
}) => {
  const handleSubmit = (value: string) => {
    const { newStartTime, newEndTime } = convertDate(value, timeEntry);
    onSubmit({ ...timeEntry, startTime: newStartTime, endTime: newEndTime });
  };

  const dateDisplay = dayjs(timeEntry.startTime).fromNow();
  const initialDateValue = dayjs(timeEntry.startTime).format("YYYY-MM-DD");

  return (
    <TransformToInputWrapper
      type="date"
      initialValue={initialDateValue}
      onSubmit={handleSubmit}
    >
      <HoverButton className="date" style={{ cursor: "text" }}>
        <P className="date" cursive>
          {dateDisplay}
        </P>
      </HoverButton>
    </TransformToInputWrapper>
  );
};

export default UpdateDate;
