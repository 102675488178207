import React from "react";
import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { Button, H1, Center } from "time-tracker-lib";

interface Props {}

const Success: React.FC<Props> = () => {
  const history = useHistory();

  return (
    <Container>
      <Row>
        <Col>
          <Center>
            <div>
              <H1>Thanks for your payment!</H1>

              <div className="mt-4">
                <Button onClick={() => history.push("/dashboard")}>
                  Track more time
                </Button>
              </div>
            </div>
          </Center>
        </Col>
      </Row>
    </Container>
  );
};

export default Success;
