import React, { useState } from "react";
import { Formik, Form } from "formik";
import {
  Button,
  H1,
  P,
  InputField,
  BottomRight,
  Center,
  TopRight,
  LayoutContainer,
} from "time-tracker-lib";
import { toErrorMap } from "@TiceTrackerApp/time-tracker-hooks/dist/utils/toErrorMap";
import { useHistory, useParams } from "react-router-dom";
import { useResetPasswordMutation } from "@TiceTrackerApp/time-tracker-hooks/dist/graphql";
import MoreDropDown from "../components/general/MoreDropDown";

const ResetPassword: React.FC<{}> = () => {
  const history = useHistory();
  const params = useParams<{ token: string }>();
  const [resetPassword] = useResetPasswordMutation();
  const [message, setMessage] = useState("");

  const handleSubmit = async (values: any, { setErrors }: any) => {
    const { data } = await resetPassword({
      variables: {
        input: values,
        token: params.token,
      },
    });
    if (data?.resetPassword.errors?.length) {
      setErrors(toErrorMap(data.resetPassword.errors));
    } else {
      setMessage("Your password has been reset!");
    }
  };

  return (
    <>
      <TopRight>
        <div className="m-1">
          <MoreDropDown leftOffsetAppear={-140}>
            <Button
              style={{ width: 185, marginTop: 10 }}
              secondary
              onClick={() => history.push("/forgot-password")}
            >
              Forgot password
            </Button>
          </MoreDropDown>
        </div>
      </TopRight>

      <LayoutContainer>
        <Center>
          <div style={{ width: "100%" }}>
            <div className="my-4 my-md-5">
              <H1>Reset your password.</H1>
            </div>
            {!message ? (
              <Formik
                initialValues={{ password: "", passwordConfirmation: "" }}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <InputField
                      name="password"
                      placeholder="Password"
                      type="password"
                    />
                    <InputField
                      name="passwordConfirmation"
                      placeholder="Retyp password"
                      type="password"
                    />
                    <Button type="submit">
                      {isSubmitting ? "Hold on..." : "Reset password"}
                    </Button>
                  </Form>
                )}
              </Formik>
            ) : (
              <>
                <P className="mb-4">{message}</P>

                <Button onClick={() => history.push("/login")}>Log in</Button>
              </>
            )}
          </div>
        </Center>
      </LayoutContainer>

      <BottomRight className="m-3">
        <Button secondary onClick={() => history.push("/login")}>
          Log in
        </Button>
      </BottomRight>
    </>
  );
};

export default ResetPassword;
