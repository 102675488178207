import React from "react";
import { UpdateTimeEntryInput } from "@TiceTrackerApp/time-tracker-hooks/dist/graphql";
import { UpdateTimeEntry } from "@TiceTrackerApp/time-tracker-hooks/dist/types";
import { useDuration } from "@TiceTrackerApp/time-tracker-hooks";
import { P } from "time-tracker-lib/dist/components/Typography";

export interface UpdateTimeEntryFieldProps {
  timeEntry: UpdateTimeEntry;
  onSubmit: (input: UpdateTimeEntryInput) => void;
}

const UpdateDuration: React.FC<UpdateTimeEntryFieldProps> = ({ timeEntry }) => {
  const { hourDisplay, minuteDisplay } = useDuration(timeEntry);

  return (
    <P className="timestamp" cursive>
      {hourDisplay} {minuteDisplay}
    </P>
  );
};

export default UpdateDuration;
