import React, { useEffect } from "react";
import AuthRequired from "../components/auth/AuthRequired";
import { useAuth, useTimeEntry } from "@TiceTrackerApp/time-tracker-hooks";
import TimeTrackerBar from "../components/dashboard/TimeTrackerBar";
import { Formik, Form } from "formik";
import LogoutButton from "../components/auth/LogoutButton";
import {
  Button,
  BottomRight,
  BottomLeft,
  Loading,
  LayoutContainer,
} from "time-tracker-lib";
import TimeEntriesTable from "../components/dashboard/TimeEntriesList";
import { useHistory } from "react-router-dom";
import useQueryParams from "../hooks/useQueryParams";
import { Box, Flex } from "ticeboxes";
import useScrollBottom from "../hooks/useScrollBottom";
import updateTimeEntryCacheOnLoadMore from "@TiceTrackerApp/time-tracker-hooks/dist/utils/cache/updateTimeEntryCacheOnLoadMore";

const Dashboard: React.FC<{}> = () => {
  const query = useQueryParams();
  const history = useHistory();

  const onAccountLimitReached = (type: string) => {
    if (type === "verification") return history.push("/verify-email");
    if (type === "plan") return history.push("/become-member?promo=launch");
  };

  const {
    onSubmitTimeEntry,
    currentTimeEntry,
    timeEntries,
    isFetching,
    fetchMore,
    handleStartExistingTimeEntry,
  } = useTimeEntry({ onAccountLimitReached });

  const auth = useAuth();

  const { scrolledToBottom, checkIfScrolledToBottom } = useScrollBottom();

  /**
   * Handle lazy loading when scrolled to bottom
   */
  useEffect(() => {
    if (checkIfScrolledToBottom() && !isFetching && timeEntries) {
      fetchMore({
        variables: { offset: timeEntries?.length },
        updateQuery: updateTimeEntryCacheOnLoadMore,
      });
    }
  }, [
    scrolledToBottom,
    isFetching,
    timeEntries,
    checkIfScrolledToBottom,
    fetchMore,
  ]);

  /**
   * Handle when having ?description=...&projectName=... in the params
   */
  useEffect(() => {
    const description = query.get("description");
    const projectName = query.get("projectName");
    const hasParams = description && projectName;

    const topTimeEntry = timeEntries?.[0];

    const isRunning = !topTimeEntry?.endTime;

    if (!isFetching && hasParams) {
      history.replace("/dashboard");
    }

    if (!isFetching && !isRunning && hasParams) {
      handleStartExistingTimeEntry({
        description,
        projectName,
      });
    }
  }, [
    query,
    currentTimeEntry,
    timeEntries,
    handleStartExistingTimeEntry,
    history,
    isFetching,
  ]);

  return (
    <AuthRequired>
      <LayoutContainer size="wide" className="my-5">
        <Box mb="4">
          <Formik
            enableReinitialize={true}
            initialValues={currentTimeEntry}
            onSubmit={onSubmitTimeEntry}
          >
            <Form>
              <TimeTrackerBar timeEntry={currentTimeEntry} />
            </Form>
          </Formik>
        </Box>

        {!isFetching && timeEntries && (
          <div style={{ overflowY: "scroll" }} className="mb-5">
            <TimeEntriesTable
              timeEntries={timeEntries}
              onStartTimeEntry={handleStartExistingTimeEntry}
            />
            {isFetching && (
              <Box mt="3" style={{ width: "100%", position: "absolute" }}>
                <Flex justify-content="center">
                  <Loading size="large" />
                </Flex>
              </Box>
            )}
          </div>
        )}
      </LayoutContainer>

      {!auth?.fullMember && (
        <BottomLeft className="m-3">
          <Button onClick={() => history.push("/become-member?promo=launch")}>
            Join
          </Button>
        </BottomLeft>
      )}

      {auth?.role === 1 && (
        <BottomLeft className="m-3">
          <Button onClick={() => history.push("/stats")}>Stats</Button>
        </BottomLeft>
      )}

      <BottomRight className="m-3">
        <LogoutButton />
      </BottomRight>
    </AuthRequired>
  );
};

export default Dashboard;
