import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "@TiceTrackerApp/time-tracker-hooks";

const Home: React.FC<{}> = () => {
  const history = useHistory();

  const auth = useAuth();

  useEffect(() => {
    if (auth === null) {
      history.push("/login");
    } else if (auth) {
      history.push("/dashboard");
    }
  }, [auth, history]);

  return <></>;
};

export default Home;
