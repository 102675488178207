import React from "react";
import Tour from "../wizard/Tour";
import setupTour from "../wizard/tours/setupTour";
import { useAuth } from "@TiceTrackerApp/time-tracker-hooks";
import { useCompleteOnBoardingMutation } from "@TiceTrackerApp/time-tracker-hooks/dist/graphql";
import { useLocation } from "react-router-dom";

interface Props {}

const TimeTrackerTour: React.FC<Props> = () => {
  const auth = useAuth();
  const location = useLocation();
  const [completeOnBoarding] = useCompleteOnBoardingMutation();

  const showTour =
    auth &&
    !auth.onBoarded &&
    (location.pathname.match(/dashboard/) ||
      location.pathname.match(/project/));

  const onCompleteTour = () => completeOnBoarding();

  return showTour ? (
    <Tour tour={setupTour} onComplete={onCompleteTour} />
  ) : null;
};

export default TimeTrackerTour;
