import React, { useEffect } from "react";
import {
  H1,
  H2,
  P,
  Button,
  FolderIcon,
  CaretLeftIcon,
  Loading,
  HoverIconButton,
  BottomRight,
  LayoutContainer,
} from "time-tracker-lib";
import { useHistory, useParams } from "react-router-dom";
import TimeEntriesTable from "../components/dashboard/TimeEntriesList";
import { useProjectQuery } from "@TiceTrackerApp/time-tracker-hooks/dist/graphql";
import NotFound from "./NotFound";
import DurationInWords from "../components/general/DurationInWords";
import { Box, Flex } from "ticeboxes";
import useViewPort from "../hooks/useViewport";
import { useTheme } from "styled-components";
import useScrollBottom from "../hooks/useScrollBottom";
import updateProjectCacheOnLoadMore from "@TiceTrackerApp/time-tracker-hooks/dist/utils/cache/updateProjectCacheOnLoadMore";

const Project: React.FC<{}> = () => {
  const params = useParams<{ id: string }>();
  const history = useHistory();

  const { data, loading, fetchMore } = useProjectQuery({
    variables: { id: +params.id },
  });

  const { scrolledToBottom, checkIfScrolledToBottom } = useScrollBottom();

  useEffect(() => {
    if (checkIfScrolledToBottom() && !loading && data) {
      fetchMore({
        variables: { offset: data?.project?.timeEntries.length },
        updateQuery: updateProjectCacheOnLoadMore,
      });
    }
  }, [scrolledToBottom, loading, data, checkIfScrolledToBottom, fetchMore]);

  const { breakPointMd } = useTheme() as any;
  const { width } = useViewPort();

  if (!data?.project && !loading) {
    return <NotFound />;
  }

  const iconSize = width > breakPointMd ? "big" : "medium";

  return loading ? (
    <LayoutContainer size="wide">
      <Flex mt="5" justify-content="center">
        <Loading />
      </Flex>
    </LayoutContainer>
  ) : (
    <>
      <LayoutContainer size="wide" className="my-5">
        <Flex align-items="center">
          <Flex align-items="center">
            <HoverIconButton
              id="projectBackBtn"
              className="mr-md-3"
              onClick={() => history.push("/dashboard")}
            >
              <CaretLeftIcon size={iconSize} />
            </HoverIconButton>
            <FolderIcon size={iconSize} />
          </Flex>
          <H1 className="flex-grow-1 text-right" display>
            {data?.project?.name}
          </H1>
        </Flex>

        {data?.project?.timeEntries.length ? (
          <H2 className="mt-5">
            <DurationInWords duration={data!.project!.totalDuration} />
          </H2>
        ) : null}

        <Box my="5" style={{ overflowY: "scroll" }}>
          {data?.project && (
            <TimeEntriesTable
              onStartTimeEntry={(entry) =>
                history.push(
                  `/dashboard?description=${entry.description}&projectName=${entry.projectName}`
                )
              }
              timeEntries={data!.project!.timeEntries}
            />
          )}

          {!data?.project?.timeEntries.length && (
            <P>No time entries for this project</P>
          )}
        </Box>
      </LayoutContainer>

      <BottomRight className="m-3">
        <Button secondary onClick={() => history.push("/dashboard")}>
          Dashboard
        </Button>
      </BottomRight>
    </>
  );
};

export default Project;
