import React from "react";
import { HoverButton } from "time-tracker-lib/dist/components/HoverButton";
import { P } from "time-tracker-lib/dist/components/Typography";
import TransformToInputWrapper from "time-tracker-lib/dist/components/form/TransformToInputWrapper";
import { UpdateTimeEntryFieldProps } from "./UpdateDuration";
import truncate from "@TiceTrackerApp/time-tracker-hooks/dist/utils/truncate";

const UpdateDescription: React.FC<UpdateTimeEntryFieldProps> = ({
  timeEntry,
  onSubmit,
}) => {
  const handleSubmit = (value: string) => {
    onSubmit({ ...timeEntry, description: value });
  };

  return (
    <TransformToInputWrapper
      onSubmit={handleSubmit}
      initialValue={timeEntry.description}
    >
      <HoverButton className="description" style={{ cursor: "text" }}>
        <P className="description">{truncate(timeEntry.description, 32)}</P>
      </HoverButton>
    </TransformToInputWrapper>
  );
};

export default UpdateDescription;
