import React from "react";
import { HoverButton } from "time-tracker-lib/dist/components/HoverButton";
import { P } from "time-tracker-lib/dist/components/Typography";
import TransformToInputWrapper from "time-tracker-lib/dist/components/form/TransformToInputWrapper";
import { UpdateTimeEntryFieldProps } from "./UpdateDuration";
import truncate from "time-tracker-lib/dist/utils/truncate";

const UpdateProjectName: React.FC<UpdateTimeEntryFieldProps> = ({
  timeEntry,
  onSubmit,
}) => {
  const handleSubmit = (value: string) => {
    onSubmit({ ...timeEntry, projectName: value });
  };

  return (
    <TransformToInputWrapper
      initialValue={timeEntry.projectName}
      onSubmit={handleSubmit}
    >
      <HoverButton className="project" style={{ cursor: "text" }}>
        <P className="project">{truncate(timeEntry.projectName, 12)}</P>
      </HoverButton>
    </TransformToInputWrapper>
  );
};

export default UpdateProjectName;
