import React from "react";
import styled from "styled-components";
import { H2, P } from "time-tracker-lib";

const StyledTable = styled.table`
  width: 100%;
  tr:nth-child(even) {
    background-color: white;
  }
  td {
    padding: 12px 18px;
    white-space: nowrap;
  }
`;

interface Props {
  stats: Stats;
}

type Stats = {
  accountsToday: number;
  accountsThisWeek: number;
  accountsEver: number;
  membersToday: number;
  membersThisWeek: number;
  membersEver: number;
};

const StatTable: React.FC<Props> = ({ stats }) => {
  return (
    <StyledTable>
      <thead>
        <tr>
          <th></th>
          <th>
            <P cursive>Accounts</P>
          </th>
          <th>
            <P cursive>Members</P>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <P>Today</P>
          </td>
          <td>
            <H2>{stats.accountsToday}</H2>
          </td>
          <td>
            <H2>{stats.membersToday}</H2>
          </td>
        </tr>
        <tr>
          <td>
            <P>This week</P>
          </td>
          <td>
            <H2>{stats.accountsThisWeek}</H2>
          </td>
          <td>
            <H2>{stats.membersThisWeek}</H2>
          </td>
        </tr>
        <tr>
          <td>
            <P>Ever</P>
          </td>
          <td>
            <H2>{stats.accountsEver}</H2>
          </td>
          <td>
            <H2>{stats.membersEver}</H2>
          </td>
        </tr>
      </tbody>
    </StyledTable>
  );
};

export default StatTable;
