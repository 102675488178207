import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import apolloClient from "@TiceTrackerApp/time-tracker-hooks/dist/config/apolloClient";
import "./config/sentry";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { ThemeWrapper } from "time-tracker-lib";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeWrapper>
        <ApolloProvider client={apolloClient}>
          <App />
        </ApolloProvider>
      </ThemeWrapper>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();
