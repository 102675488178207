import React from "react";
import styled from "styled-components";
import { Box, Flex } from "ticeboxes";
import { HoverIconButton } from "time-tracker-lib/dist/components/HoverButton";
import { HoverIconLink } from "time-tracker-lib/dist/components/HoverLink";
import StopwatchButton from "time-tracker-lib/dist/components/StopwatchButton";
import { P } from "time-tracker-lib/dist/components/Typography";
import BinIcon from "time-tracker-lib/dist/components/icons/BinIcon";
import FolderIcon from "time-tracker-lib/dist/components/icons/FolderIcon";
import UpdateDate from "./UpdateDate";
import UpdateDescription from "./UpdateDescription";
import UpdateDuration from "./UpdateDuration";
import UpdateProjectName from "./UpdateProjectName";
import UpdateTime from "./UpdateTime";
import { Entry } from "@TiceTrackerApp/time-tracker-hooks/dist/types";

const StyledTable = styled.table`
  width: 100%;
  tr:nth-child(odd) {
    background-color: white;
  }
  td {
    padding: 12px 18px;
    white-space: nowrap;
  }
`;

interface Props {
  timeEntries: Entry[];
  onStart: (entry: Entry) => void;
  onEdit: (entry: Entry) => void;
  onRemove: (id: number) => void;
}

const Table: React.FC<Props> = ({ timeEntries, onStart, onEdit, onRemove }) => {
  return (
    <StyledTable>
      <tbody>
        {timeEntries.map((entry) => {
          if (!entry.endTime) return null;

          return (
            <tr key={entry.id}>
              <td>
                <Box style={{ position: "relative", bottom: 2 }}>
                  <StopwatchButton
                    size="small"
                    onClick={() => onStart(entry)}
                  />
                </Box>
              </td>
              <td>
                <Flex align-items="baseline">
                  <UpdateTime
                    type="startTime"
                    timeEntry={entry}
                    onSubmit={onEdit}
                  />
                  <P cursive className="mx-1">
                    until
                  </P>
                  <UpdateTime
                    type="endTime"
                    timeEntry={entry}
                    onSubmit={onEdit}
                  />
                </Flex>
              </td>

              <td>
                <UpdateDate timeEntry={entry} onSubmit={onEdit} />
              </td>

              <td>
                <UpdateDuration timeEntry={entry} onSubmit={onEdit} />
              </td>

              <td>
                <UpdateDescription timeEntry={entry} onSubmit={onEdit} />
              </td>

              <td>
                <div className="d-flex">
                  <HoverIconLink
                    className="mr-1 folder-icon-link"
                    to={`/project/${entry.project!.id}`}
                  >
                    <FolderIcon />
                  </HoverIconLink>

                  <UpdateProjectName timeEntry={entry} onSubmit={onEdit} />
                </div>
              </td>

              <td>
                <HoverIconButton onClick={() => onRemove(entry.id)}>
                  <BinIcon />
                </HoverIconButton>
              </td>
            </tr>
          );
        })}
      </tbody>
    </StyledTable>
  );
};

export default Table;
