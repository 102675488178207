import React, { useEffect } from "react";
import { useMeQuery } from "@TiceTrackerApp/time-tracker-hooks/dist/graphql";
import { useHistory } from "react-router-dom";

interface Props {
  children: React.ReactNode | React.ReactNodeArray;
}

const SuperAdminRequired: React.FC<Props> = ({ children }) => {
  const history = useHistory();

  const { loading, data } = useMeQuery();

  useEffect(() => {
    if (!loading && !data?.me) {
      history.push("/login");
    }

    if (!loading && data?.me) {
      if (data?.me.role !== 1) {
        history.replace("/404");
      }
    }
  }, [loading, data, history]);

  return !loading ? <>{children}</> : null;
};

export default SuperAdminRequired;
