import React from "react";
import { Formik, Form } from "formik";
import {
  Button,
  InputField,
  H1,
  BottomRight,
  Center,
  TopRight,
  LayoutContainer,
} from "time-tracker-lib";
import { useLoginMutation } from "@TiceTrackerApp/time-tracker-hooks/dist/graphql";
import { toErrorMap } from "@TiceTrackerApp/time-tracker-hooks/dist/utils/toErrorMap";
import { useHistory } from "react-router-dom";
import MoreDropDown from "../components/general/MoreDropDown";
import updateCacheOnLogin from "@TiceTrackerApp/time-tracker-hooks/dist/utils/cache/updateCacheOnLogin";
import { Box } from "ticeboxes";
import UnauthRequired from "../components/auth/UnauthRequired";

const Login: React.FC<{}> = () => {
  const history = useHistory();
  const [login] = useLoginMutation();

  const handleSubmit = async (values: any, { setErrors }: any) => {
    const { data } = await login({
      variables: values,
      update: updateCacheOnLogin,
    });

    if (data?.login.errors?.length) {
      setErrors(toErrorMap(data.login.errors));
    } else {
      history.push("/dashboard");
    }
  };

  return (
    <>
      <UnauthRequired />

      <TopRight>
        <Box m="1">
          <MoreDropDown leftOffsetAppear={-140}>
            <Button
              style={{ width: 185, marginTop: 10 }}
              secondary
              onClick={() => history.push("/forgot-password")}
            >
              Forgot password
            </Button>
          </MoreDropDown>
        </Box>
      </TopRight>

      <LayoutContainer>
        <Center>
          <Box style={{ width: "100%" }}>
            <Box my="4" my-md="5">
              <H1>Log in.</H1>
            </Box>
            <Formik
              initialValues={{ email: "", password: "" }}
              onSubmit={handleSubmit}
            >
              {() => (
                <Form>
                  <InputField name="email" placeholder="Email" />
                  <InputField
                    name="password"
                    placeholder="Password"
                    type="password"
                  />
                  <Button type="submit">Log in</Button>
                </Form>
              )}
            </Formik>
          </Box>
        </Center>
      </LayoutContainer>

      <BottomRight className="m-3">
        <Button secondary onClick={() => history.push("/register")}>
          Register
        </Button>
      </BottomRight>
    </>
  );
};

export default Login;
