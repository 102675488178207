import { loadStripe } from "@stripe/stripe-js";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { Button, H1, H2, P, BottomRight, Center } from "time-tracker-lib";
import { ErrorMessage } from "time-tracker-lib/dist/components/form/Form";
import { useMeQuery } from "@TiceTrackerApp/time-tracker-hooks/dist/graphql";
import { CLIENT_URL } from "../../constants";
import {
  STRIPE_PK,
  STRIPE_SKU,
  STRIPE_SKU_DISCOUNT,
} from "@TiceTrackerApp/time-tracker-hooks/dist/constants";
import { Box, Flex } from "ticeboxes";
import useQueryParams from "../../hooks/useQueryParams";

const stripePromise = loadStripe(STRIPE_PK!);

const planSku = STRIPE_SKU;
const discountPlanSku = STRIPE_SKU_DISCOUNT;

type HandlePaymentOpts = {
  isDiscount?: boolean;
};

const Upgrade: React.FC<{}> = () => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const [error, setError] = useState("");
  const { data, loading } = useMeQuery();

  const handlePayment = async ({ isDiscount }: HandlePaymentOpts = {}) => {
    const stripe = await stripePromise;

    const sku = isDiscount ? discountPlanSku : planSku;

    const response = await stripe?.redirectToCheckout({
      lineItems: [{ price: sku, quantity: 1 }],
      clientReferenceId: data?.me?.id.toString(),
      mode: "subscription",
      successUrl: `${CLIENT_URL}/payment-success`,
      cancelUrl: `${CLIENT_URL}/payment-cancel`,
    });

    if (response?.error) {
      setError("There was an error processing your payment, please try again.");
    }
  };

  const isPromo = queryParams.get("promo") === "launch";

  return (
    <Container>
      <Row>
        <Col>
          {!loading && (
            <Center>
              <div>
                {data?.me?.fullMember ? (
                  <H1>You already have unlimited access!</H1>
                ) : (
                  <>
                    <H1>Get unlimited access.</H1>

                    {isPromo ? (
                      <>
                        <Box>
                          <Flex className="mt-4 mb-2" align-items="baseline">
                            <P className="mr-2" strikeThru>
                              $5
                            </P>
                            <H2 className="mr-2">$2</H2>
                            <P className="mb-1">a month</P>
                          </Flex>
                          <Box mb="4">
                            <P cursive>If you purchase for 1 year.</P>
                          </Box>
                        </Box>
                        <Flex>
                          <Button
                            onClick={() => handlePayment({ isDiscount: true })}
                          >
                            Pay now
                          </Button>
                          <Button
                            className="ml-2"
                            small
                            transparent
                            onClick={() => history.push("/dashboard")}
                          >
                            Try it out first
                          </Button>
                        </Flex>
                      </>
                    ) : (
                      <>
                        <Flex className="my-4" align-items="baseline">
                          <H2 className="mr-2">$5</H2>
                          <P className="mb-1">a month</P>
                        </Flex>

                        <Button onClick={() => handlePayment()}>Pay now</Button>
                      </>
                    )}
                  </>
                )}
                <div className="mt-3">
                  <ErrorMessage content={error} />
                </div>
              </div>
            </Center>
          )}
        </Col>
      </Row>
      <BottomRight className="m-3">
        <Button secondary onClick={() => history.push("/dashboard")}>
          Dashboard
        </Button>
      </BottomRight>
    </Container>
  );
};

export default Upgrade;
