import { Step } from "../../components/wizard/Tour";

const isElementInViewForStep = (step: Step) => {
  if (!step.selector) return true;

  const elem = document.querySelector(step.selector);
  if (!elem) return true;

  const bounding = elem.getBoundingClientRect();

  if (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.right <= window.innerWidth &&
    bounding.bottom <= window.innerHeight
  ) {
    return true;
  }

  return false;
};

export default isElementInViewForStep;
