import React from "react";
import { useHistory } from "react-router-dom";
import { Box } from "ticeboxes";
import { H1, Center, Button, LayoutContainer } from "time-tracker-lib";

const NotFound: React.FC<{}> = () => {
  const history = useHistory();

  return (
    <LayoutContainer>
      <Center>
        <Box>
          <H1>Not Found.</H1>
          <Box my="5">
            <Button onClick={() => history.push("/dashboard")}>
              Go to dashboard
            </Button>
          </Box>
        </Box>
      </Center>
    </LayoutContainer>
  );
};

export default NotFound;
