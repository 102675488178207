import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import NotFound from "./pages/NotFound";
import ResetPassword from "./pages/ResetPassword";
import Project from "./pages/Project";
import Upgrade from "./pages/plan/Upgrade";
import Cancel from "./pages/plan/Cancel";
import Success from "./pages/plan/Success";
import Stats from "./pages/superadmin/Stats";
import TimeTrackerTour from "./components/wizard/TimeTrackerTour";
import ConfirmAccount from "./pages/ConfirmAccount";
import VerifyEmail from "./pages/VerifyEmail";

const App = () => {
  const location = useLocation();

  if (process.env.NODE_ENV === "production") {
    ReactGA.initialize("G-PBCZ8W6F90");
  }

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.initialize("G-PBCZ8W6F90");
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
    }
  }, [location]);

  return (
    <>
      <TimeTrackerTour />

      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/reset-password/:token" component={ResetPassword} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/project/:id" component={Project} />
        <Route exact path="/become-member" component={Upgrade} />
        <Route exact path="/payment-success" component={Success} />
        <Route exact path="/payment-cancel" component={Cancel} />
        <Route
          exact
          path="/confirm-account/:token"
          component={ConfirmAccount}
        />
        <Route exact path="/verify-email" component={VerifyEmail} />
        <Route exact path="/stats" component={Stats} />
        <Route path="*" component={NotFound} />
      </Switch>
    </>
  );
};

export default App;
