import React, { useCallback, useEffect, useRef, useState } from "react";
import { wait } from "time-tracker-lib/dist/utils";
import isElementInViewForStep from "../../utils/wizard/isElementInViewForStep";
import getCardCoordsForStep from "../../utils/wizard/getCardCoordsForStep";
import {
  PosAbsoluteAnimated,
  WizardCard,
} from "time-tracker-lib/dist/components/wizard";
import { WizardCardProps } from "time-tracker-lib/dist/components/wizard/WizardCard";

import scrollElementIntoViewForStep from "../../utils/wizard/scrollElementIntoViewForStep";

export type Step = {
  selector?: string;
  text: string;
  buttonText?: string;
  buttonPlacement?: "center" | "right";
  cardPlacement?: "bottom" | "right" | "left";
  nextStepRequirement?: {
    elementSelector: string;
    event: "click" | "keyup";
    automatic?: boolean;
    finish?: boolean;
  };
};

interface Props {
  tour: Step[];
  onComplete?: () => void;
}

const Tour: React.FC<Props> = ({ tour, onComplete }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isStopped, setStopped] = useState(false);
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const [
    displayingCurrentStep,
    setDisplayingCurrentStep,
  ] = useState<WizardCardProps>({ ...tour[0], appear: false });

  const eventListenerRef = useRef<any>(null);

  const nextStep = () => setCurrentStep((prevStep) => prevStep + 1);

  const updateCardContent = useCallback(() => {
    const {
      text,
      buttonPlacement,
      buttonText,
      nextStepRequirement: req,
    } = tour[currentStep];

    // button is already active if we have no next step requirement
    const isButtonActive = !req;

    // button is hidden if next step goes automatically when requirement is passed
    const hideButton = req?.automatic;

    setDisplayingCurrentStep((prevstep) => ({
      ...prevstep,
      text,
      buttonPlacement,
      buttonText,
      isButtonActive,
      hideButton,
    }));
  }, [currentStep, tour]);

  const clearEventListener = () => {
    const { elem, e, cb } = eventListenerRef.current;
    elem.removeEventListener(e, cb);
  };

  const updateEventListener = useCallback(() => {
    const { nextStepRequirement: req } = tour[currentStep];
    if (!req) return;

    const elem = document.querySelector(req.elementSelector);
    if (!elem) return;

    eventListenerRef.current = {
      elem,
      e: req.event,
      cb: () => {
        clearEventListener();
        if (req.automatic) {
          nextStep();
        } else {
          setButtonActive();
        }
      },
    };

    elem.addEventListener(
      eventListenerRef.current.e,
      eventListenerRef.current.cb
    );
  }, [currentStep, tour]);

  const moveWizardCard = useCallback(async () => {
    if (!isElementInViewForStep(tour[currentStep])) {
      scrollElementIntoViewForStep(tour[currentStep]);
      await wait(500);
    }

    const newCoords = getCardCoordsForStep(tour[currentStep]);
    if (newCoords) setCoords(newCoords);
  }, [currentStep, tour]);

  const hideCardContent = () =>
    setDisplayingCurrentStep((prevstate) => ({
      ...prevstate,
      hideContent: true,
    }));

  const showCardContent = () =>
    setDisplayingCurrentStep((prevstate) => ({
      ...prevstate,
      hideContent: false,
    }));

  const showCard = () =>
    setDisplayingCurrentStep((prevstate) => ({ ...prevstate, appear: true }));

  const hideCard = () =>
    setDisplayingCurrentStep((prevstate) => ({ ...prevstate, appear: false }));

  const setButtonActive = () => {
    setDisplayingCurrentStep((prevstate) => ({
      ...prevstate,
      isButtonActive: true,
    }));
  };

  const updateTour = useCallback(async () => {
    hideCardContent();
    await wait(300);
    updateCardContent();
    updateEventListener();
    await moveWizardCard();
    await wait(400);
    showCardContent();
  }, [moveWizardCard, updateCardContent, updateEventListener]);

  const startTour = useCallback(async () => {
    await updateTour();
    await wait(400);
    showCard();
  }, [updateTour]);

  const stopTour = useCallback(async () => {
    hideCardContent();
    await wait(400);
    hideCard();
    await wait(400);
    setStopped(true);
    if (onComplete) onComplete();
  }, [onComplete]);

  useEffect(() => {
    setTimeout(() => {
      if (currentStep === 0) {
        startTour();
      } else if (currentStep === tour.length) {
        stopTour();
      } else {
        updateTour();
      }
    }, 200);
  }, [currentStep, startTour, stopTour, updateTour, tour.length]);

  return !isStopped ? (
    <PosAbsoluteAnimated x={coords.x} y={coords.y}>
      <WizardCard {...displayingCurrentStep} onClickButton={nextStep} />
    </PosAbsoluteAnimated>
  ) : null;
};

export default Tour;
