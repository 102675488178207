import React from "react";
import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { Button, H1, Center } from "time-tracker-lib";

interface Props {}

const Cancel: React.FC<Props> = () => {
  const history = useHistory();

  return (
    <Container>
      <Row>
        <Col>
          <Center>
            <div style={{ width: "100%" }}>
              <H1>Payment canceled.</H1>

              <div className="mt-4 d-md-flex justify-content-between">
                <Button onClick={() => history.push("/become-member")}>
                  Try again
                </Button>
                <Button
                  className="mt-3 mt-md-0"
                  secondary
                  onClick={() => history.push("/dashboard")}
                >
                  Back to dashboard
                </Button>
              </div>
            </div>
          </Center>
        </Col>
      </Row>
    </Container>
  );
};

export default Cancel;
