import React, { useState } from "react";
import { Formik, Form } from "formik";
import {
  H1,
  P,
  Button,
  InputField,
  Center,
  BottomRight,
  LayoutContainer,
} from "time-tracker-lib";
import { toErrorMap } from "@TiceTrackerApp/time-tracker-hooks/dist/utils/toErrorMap";
import { useHistory } from "react-router-dom";
import { useForgotPasswordMutation } from "@TiceTrackerApp/time-tracker-hooks/dist/graphql";
import redirectToEmail from "../utils/redirectToEmail";

const ForgotPassword: React.FC<{}> = () => {
  const history = useHistory();
  const [forgotPassword] = useForgotPasswordMutation();
  const [message, setMessage] = useState("");

  const handleSubmit = async (values: any, { setErrors }: any) => {
    const { data } = await forgotPassword({ variables: values });
    if (data?.forgotPassword.errors?.length) {
      setErrors(toErrorMap(data.forgotPassword.errors));
    } else {
      setMessage(
        "If an account exists, a mail has been sent. Check your mail:"
      );
    }
  };

  return (
    <>
      <LayoutContainer>
        <Center>
          <div style={{ width: "100%" }}>
            <div className="my-4 my-md-5">
              <H1>Shit happens.</H1>
            </div>
            {!message ? (
              <Formik initialValues={{ email: "" }} onSubmit={handleSubmit}>
                {({ isSubmitting }) => (
                  <Form>
                    <InputField name="email" placeholder="Email" type="email" />
                    <Button type="submit">
                      {isSubmitting ? "Hold on..." : "Send reset email"}
                    </Button>
                  </Form>
                )}
              </Formik>
            ) : (
              <>
                <P>{message}</P>
                <div className="mt-3 d-flex">
                  <Button
                    onClick={redirectToEmail("gmail")}
                    secondary
                    className="mr-3"
                  >
                    Gmail
                  </Button>
                  <Button onClick={redirectToEmail("outlook")} secondary>
                    Hotmail
                  </Button>
                </div>
              </>
            )}
          </div>
        </Center>
      </LayoutContainer>

      <BottomRight className="m-3">
        <Button secondary onClick={() => history.push("/login")}>
          Log in
        </Button>
      </BottomRight>
    </>
  );
};

export default ForgotPassword;
