import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box, Flex } from "ticeboxes";
import { Button, H1, Center, LayoutContainer } from "time-tracker-lib";
import {
  useConfirmAccountMutation,
  useResendActivationEmailMutation,
} from "@TiceTrackerApp/time-tracker-hooks/dist/graphql";
import redirectToEmail from "../utils/redirectToEmail";

interface Props {}

const ConfirmAccount: React.FC<Props> = () => {
  const params = useParams<{ token: string }>();
  const history = useHistory();
  const [
    confirmAccount,
    { loading, error, data },
  ] = useConfirmAccountMutation();

  const [
    resendActivationEmail,
    { data: resentData },
  ] = useResendActivationEmailMutation();

  useEffect(() => {
    if (params.token) {
      confirmAccount({ variables: { token: params.token } });
    }
  }, [confirmAccount, params.token]);

  const hasError = error || data?.confirmAccount.errors?.length;
  const success = data?.confirmAccount.success;

  const resentActivationEmail = resentData?.resendActivationEmail.success;

  let title = "Loading...";
  if (!loading && hasError) title = "Link expired.";
  if (!loading && success) title = "All set!";
  if (resentActivationEmail) title = "Check your email";

  const onResendActivationEmail = async () => {
    await resendActivationEmail();
  };

  return (
    <LayoutContainer>
      <Center>
        <Box>
          <H1>{title}</H1>
          <Box mt="3">
            {resentActivationEmail ? (
              <Flex>
                <Button
                  className="mr-2"
                  secondary
                  onClick={redirectToEmail("gmail")}
                >
                  Gmail
                </Button>
                <Button secondary onClick={redirectToEmail("gmail")}>
                  Outlook
                </Button>
              </Flex>
            ) : (
              <>
                {success ? (
                  <Button onClick={() => history.push("/dashboard")}>
                    Go track more time
                  </Button>
                ) : null}

                {hasError ? (
                  <Button secondary onClick={onResendActivationEmail}>
                    Resend activation email
                  </Button>
                ) : null}
              </>
            )}
          </Box>
        </Box>
      </Center>
    </LayoutContainer>
  );
};

export default ConfirmAccount;
