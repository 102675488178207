import { Step } from "../../components/wizard/Tour";

const getCardCoordsForStep = (step: Step) => {
  const spacing = 15; // spacing between element and card
  const { selector, cardPlacement = "bottom" } = step;
  const cardDiv = document.querySelector("#wizardCard")!;

  if (selector) {
    const element = document.querySelector(selector);
    if (!element) return;

    const rect = element.getBoundingClientRect();
    if (!rect) return;

    let { top, left } = rect;

    if (cardPlacement === "bottom") {
      top = top + element.clientHeight + spacing;
    } else if (cardPlacement === "right") {
      left = left + element.clientWidth + spacing;
    } else if (cardPlacement === "left") {
      const x = left - (element.clientWidth + cardDiv.clientWidth);
      left = x <= spacing ? spacing : x;
    }

    return { x: left, y: top };
  } else {
    // center card in window
    const cardDiv = document.querySelector("#wizardCard");
    if (!cardDiv) return;

    const { clientWidth, clientHeight } = cardDiv;

    const x = window.innerWidth / 2 - clientWidth / 2;
    const y = window.innerHeight / 2 - clientHeight / 2;

    return { x, y };
  }
};

export default getCardCoordsForStep;
